import React from "react"
import PrivacyPolicyTemplate from "../../../components/PrivacyPolicyTemplate"

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyTemplate
      name="Centre ophtalmologique Saint Paul"
      mail="ophsaintpaul@gmail.com"
    />
  )
}

export default PrivacyPolicy
